var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-sheet',{staticClass:"mx-auto stiky py-4",attrs:{"max-width":"800"}},[_c('v-slide-group',{attrs:{"center-active":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.main_menu_list),function(category){return _c('v-slide-item',{key:category.main_menu_id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',{staticClass:"pl-5",on:{"click":function($event){return _vm.showListItem(category)}}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-card',{staticClass:"ma-4",attrs:{"dark":"","elevation":"2","rounded":"circle","color":active ? 'grey darken-4' : 'grey darken-1',"height":"60","width":"60"},on:{"click":toggle}},[_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('h6',{staticClass:"headline font-weight-bold grey--grey"},[_vm._v(" "+_vm._s(category.main_menu_name.charAt(0))+" ")])])],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center","dense":""}},[_c('h5',{attrs:{"grey--text":""}},[_vm._v(_vm._s(category.main_menu_name))])])],1)]}}],null,true)})}),1)],1),_c('v-container',{staticClass:"mt-8",attrs:{"fluid":""}},[(_vm.loading)?_c('v-row',{staticClass:"pb-16",attrs:{"justify":"center"}},[_c('v-overlay',[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1):(!_vm.main_menu_list)?_c('v-row',{staticClass:"pb-16",attrs:{"justify":"center"}},[_c('h1',[_vm._v("Data not found")])]):_c('v-row',[_c('v-col',{staticClass:"px-0 px-2",attrs:{"cols":"12"}},[_vm._l((_vm.menus),function(category){return _c('v-list',{key:category.main_menu_id,ref:category.main_menu_id,refInFor:true,staticClass:"pa-0",attrs:{"flat":"","id":'id' + category.main_menu_id,"light":false}},[_c('v-divider'),_c('v-list-group',{attrs:{"no-action":"","color":"black","value":_vm.selectedCategory.main_menu_id === category.main_menu_id
                ? true
                : false},on:{"click":function($event){return _vm.showListItem(category)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"h6 font-weight-medium"},[_vm._v(_vm._s(category.main_menu_name))])],1)]},proxy:true}],null,true)},[_c('v-divider'),_c('v-list',{attrs:{"color":"grey lighten-5"}},[_c('v-virtual-scroll',{attrs:{"items":category.sub_menus,"item-height":30,"height":30 * category.sub_menus.length},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var item = ref.item;
return [_c('v-list-item',{staticStyle:{"min-height":"26px"}},[_c('v-list-item-icon',{staticClass:"ma-0 mt-1"},[(item.menu_type == 'veg')?_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v("mdi-square-circle")]):_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v("mdi-square-circle")])],1),_c('v-list-item-content',{staticClass:"pa-0 ma-0 my-1"},[_c('v-row',{attrs:{"dense":"","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-list-item-title',{staticClass:"subtitle-2 item-title"},[_vm._v(" "+_vm._s(item.sub_menu_name)+" ")])],1),_c('v-col',{staticClass:"text-end",attrs:{"cols":"4"}},[_c('v-list-item-title',{staticClass:"subtitle-2"},[_vm._v("Rs. "+_vm._s(item.price_without_tax))])],1)],1)],1)],1)]}}],null,true)})],1)],1)],1)}),_c('v-divider')],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }